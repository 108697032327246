<template>
  <ul class="link-collection">
    <li
      v-for="(menuItem, menuItemIdx) in contentView"
      :key="menuItemIdx"
      class="link-collection__item"
    >
      <SplNuxtLink
        :to="menuItem.url"
        :override-lang="menuItem.lang"
        is-external
        :no-lang="noLang"
        :rel="relAlternate ? 'alternate' : ''"
        :is-target-blank="menuItem.targetBlank"
        :class="{ 'link-collection__item--disabled': !menuItem.url }"
      >
        <div class="inline">
          <template v-if="shouldDisplayImage(menuItem)">
            <span v-if="getImageTag(menuItem) === 'es'">
              <img
                class="link-collection__item__img"
                src="~/assets/images/fun-with-flags/es.png"
              >
            </span>
            <span v-else-if="getImageTag(menuItem) === 'pt'">
              <img
                class="link-collection__item__img"
                src="~/assets/images/fun-with-flags/pt.png"
              >
            </span>
          </template>

          <DynLang
            v-if="!hideLabels"
            capitalize-first
            :by-key="menuItem.title"
            :default-label="menuItem.title"
          />
        </div>
      </SplNuxtLink>
      <button
        v-if="hasChildren(menuItem)"
        class="link-collection__dropdown-button"
      >
        <ArrowIcon
          :size="18"
          direction="bottom"
        />
      </button>
      <ul
        class="link-collection__item__submenu"
      >
        <li
          v-for="(menuSubItem, menuSubItemIdx) in menuItem.children"
          :key="menuSubItemIdx"
          class="link-collection__item__submenu__item"
        >
          <SplNuxtLink
            :to="menuSubItem.url"
            :override-lang="menuSubItem.lang"
            is-external
            :no-lang="noLang"
            :rel="relAlternate ? 'alternate' : ''"
            :is-target-blank="menuSubItem.targetBlank"
            class="link-collection__item__submenu__link"
          >
            <div class="inline">
              <template v-if="shouldDisplayImage(menuSubItem)">
                <span v-if="getImageTag(menuSubItem) === 'es'">
                  <img
                    class="link-collection__item__img"
                    src="~/assets/images/fun-with-flags/es.png"
                  >
                </span>
                <span v-else-if="getImageTag(menuSubItem) === 'pt'">
                  <img
                    class="link-collection__item__img"
                    src="~/assets/images/fun-with-flags/pt.png"
                  >
                </span>
              </template>

              <DynLang
                capitalize-first
                :by-key="menuSubItem.title"
                :default-label="menuSubItem.title"
              />
            </div>
          </SplNuxtLink>
        </li>
      </ul>
    </li>
  </ul>
</template>

<script lang="ts">
import SplNuxtLink from '../SplNuxtLink.vue'

import ArrowIcon from '@/core/components/shared/icons/Arrow.icon.vue'
import SmallArrowIcon from '../icons/SmallArrow.icon.vue'

import { type MenuItem } from '~/core/ts/entity/MenuItem'

export default defineNuxtComponent({
  components: {
    SplNuxtLink,
    ArrowIcon,
    SmallArrowIcon,
  },
  props: {
    content: {
      type: Array as PropType<Array<MenuItem>>,
      default: () => [],
    },
    noLang: {
      type: Boolean,
      default: false,
    },
    relAlternate: {
      type: Boolean,
      default: false,
    },
    hideLabels: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    function hasChildren(item: MenuItem) {
      if (!item.children) {
        return false
      }

      return item.children.length > 0
    }

    const contentView = computed(() => {
      return props.content
    })

    function shouldDisplayImage(item: MenuItem) {
      if (!item.metadata) {
        return false
      }

      if (!item.metadata.imgByKey) {
        return false
      }

      return true
    }

    function getImageTag(item: MenuItem) {
      if (!item.metadata) {
        return ''
      }

      if (!item.metadata.imgByKey) {
        return ''
      }

      return item.metadata.imgByKey
    }

    return {
      hasChildren,
      shouldDisplayImage,
      getImageTag,

      contentView,
    }
  },
})
</script>

<style lang="scss" scoped>
@mixin borderStyle {
  @apply border-white;
  @apply border-opacity-20;
  @apply border-solid;
}

.inline {
  @apply flex justify-start;
  @apply items-center;
  @apply gap-2;
  @apply flex-1;
}

img {
  @apply w-6;
  @apply max-w-8;

  @apply border-1;
  @apply border-spl-primary;
  @apply rounded-full;
}

.link-collection {
  @apply flex justify-around;
  @apply text-white;

  &__dropdown-button {
    @apply ml-7.5p;
    @apply transition-transform duration-200 ease-in-out;
  }

  &__item {
    @apply cursor-pointer;
    @apply relative;
    @apply h-full;
    @apply h-40p;
    @apply flex items-center justify-between;

    @apply px-2;

    @screen md {
      @apply px-4;
    }

    @apply border-white;
    @apply border-opacity-20;
    @apply border-solid;
    @apply border-r-2;

    &:hover > &__submenu {
      @apply block;
      @apply opacity-100;
      @apply visible;

      -webkit-box-shadow: 0px 6px 8px -3px rgba(0, 0, 0, 0.75);
      -moz-box-shadow: 0px 6px 8px -3px rgba(0, 0, 0, 0.75);
      box-shadow: 0px 6px 8px -3px rgba(0, 0, 0, 0.75);
      @apply rounded-b-md;
    }

    &:hover > button {
      @apply transform -rotate-180;
    }

    &--disabled {
      @apply pointer-events-none;
    }

    &__submenu {
      @apply bg-white;
      min-width: 120%;
      @apply hidden;

      @apply transition-opacity duration-300 ease-in-out;

      &__link {
        @apply whitespace-nowrap;

        &:hover {
          @apply no-underline;
        }
      }

      @apply opacity-0;
      @apply absolute;
      @apply top-40p;
      left: -10%;
      @apply py-15p px-20p;
      @apply z-30;

      &__item {
        @apply text-spl-dark;
        @apply py-10p;
        @apply border-b;

        &:last-child {
          @apply border-none;
        }

        &:first-child {
          @apply text-spl-primary;
          @apply font-bold italic;
        }

        &:hover {
          @apply no-underline;
        }

        @apply ml-1;
        @apply pr-2;
      }
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  opacity: 1;
  transition: 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
