export type LocationType = 'country' | 'community' | 'region' | 'city'

export type LocationTextsDTO = {
  langcode: string,
  label: string,
  long_description: string,
  short_description: string,
  slug: string,
}

export type LocationDTO = {
  uuid: string,
  locality_parents: Array<string>,
  label: string,
  descriptions: Array<LocationTextsDTO>,
  latitude: number,
  longitude: number,
  type: LocationType,
  slug: string,

  // images: Array<any>,
  // metadata: any,

  iso2: string,

  uuid_parent: string,
  political_parent: LocationDTO | undefined,

  payload: string[],
}

export type LocationTexts = {
  langcode: string,
  label: string,
  longDescription: string,
  shortDescription: string,
  slug: string,
}

export type LocationLandings = {
  spas: {
    url: string,
    payloadLength: number,
  },
  getAways: {
    url: string,
    payloadLength: number,
  },
  promotions: Record<string, { url: string, payloadLength: number, }>,
  treatments: Record<string, { url: string, payloadLength: number, }>,
}

function getLocationTypeLabel(loc: LocationDTO): string {
  if (loc.iso2 === 'PT' && loc.type === 'community') {
    if (!loc.slug.includes('acores') && !loc.slug.includes('madeira')) {
      return `loc-type-district`
    }
  }

  return `loc-type-${loc.type}`
}

export class Location {
  constructor(
    public uuid: string,
    public label: string,
    public parentUUID: string | null,
    public texts: Record<string, LocationTexts>,

    public type: LocationType,
    public typeLabel: string,

    // ISO 2
    public countryCode: string,
    public slug: string,
    public urls: LocationLandings,
  ) { }

  static from(dto: LocationDTO, urls: LocationLandings, targetLang: string): Location {
    let label = dto.label
    let slug = dto.slug

    const texts: Record<string, LocationTexts> = {
      es: {
        langcode: 'es',
        label: dto.label,
        slug: dto.slug,
        longDescription: '',
        shortDescription: '',
      }
    }
    dto.descriptions.forEach((descDTO) => {
      texts[descDTO.langcode] = {
        langcode: descDTO.langcode,
        label: descDTO.label,
        longDescription: descDTO.long_description,
        shortDescription: descDTO.short_description,
        slug: descDTO.slug,
      }

      if (targetLang === descDTO.langcode) {
        label = descDTO.label
        slug = descDTO.slug
      }
    })

    let parentUUID = dto.political_parent ? dto.political_parent.uuid : null
    if (!parentUUID && dto.uuid_parent) {
      parentUUID = dto.uuid_parent
    }

    const typeLabel = getLocationTypeLabel(dto)

    return new Location(
      dto.uuid,
      label,
      parentUUID,
      texts,
      dto.type,
      typeLabel,
      dto.iso2,
      slug,
      urls,
    )
  }
}

export type LocationTree = {
  location: Location;
  childLocations: Array<LocationTree>;
}

export class LocationCollection {
  private locationByUUID: Record<string, Location>

  constructor(locations: Array<Location>) {
    this.locationByUUID = {}
    locations.forEach((loc) => {
      this.locationByUUID[loc.uuid] = loc
    })
  }

  public getParentFrom(loc: Location): Location | null {
    if (!loc.parentUUID) {
      return null
    }

    const maybe = this.locationByUUID[loc.parentUUID]
    if (!maybe) {
      return null
    }
    return maybe
  }

  public getCountryFrom(loc: Location): Location {
    let cursor = loc

    while (cursor && cursor.parentUUID && cursor.type !== 'country') {
      cursor = this.locationByUUID[cursor.parentUUID]
    }

    return cursor
  }
}
